<template>
    <div id= "factormanagepage">
    <div class="backpage">
      <a href="/taskmanager" class="backbutton">{{ symbol }}任务面板</a>  
    </div>
    <div id="goalmanage">
    <div id="factorshow">
    <h2>你的评价算法因子
      <button id="addgoalbutton" @click="showaddfactorzone_func" v-show="showaddbutton"> <a-tooltip title="定制自已的因子" color="red" mouseEnterDelay="1">
      +</a-tooltip></button>
      <manualicon v-show="!showaddbutton" title="非会员用户,仅支持特定数量的定制因子"/>

    </h2>
    <div class="factorzone"> 
    <ul v-for="item in items" :key="item.id" class="singlefactor"  @click="showeditfactorzone_func(item)">
      <div > {{item.Name}} </div>
    </ul>
    </div>
    </div>


    <div class="factormanage" v-show="showaddfactorzone">
      <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
      <h2>添加评价因子</h2>
      <div class="tip">输入你的因子名</div>
     
         <input placeholder="请输入因子名" v-model="factorcontent" >
         <div class="tip">选择该因子的权重</div>
          <a-select
          ref="select"
          v-model:value="selectedpriority"
          style="width: 120px"
          :options="options1"
          @focus="focus"
          @change="handleChange"
        ></a-select>
       <button @click="createfactor">提交你的评价因子</button>
    </div>
    
    <div  class="factormanage" v-show="showeditfactorzone">
      <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
      <h2>编辑因子</h2>
      <div class="tip">输入你的因子名</div>
         <input placeholder="请输入你的评价因子名" v-model="factorcontent" >
         <div class="tip">修改你的因子权重</div>
       <a-select
          ref="select"
          v-model:value="selectedpriority"
          style="width: 120px"
          :options="options1"
          @focus="focus"
          @change="handleChange"
        ></a-select>
    
      <button @click="modifyfactor">修改因子</button>
      
    </div>
    
    <!-- <div id="editgoal">
    
    </div> -->
    </div>
    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import configure_options from '../configure'
    import  manualicon from "../svg/manualicon.vue"
    import { defineComponent,ref } from 'vue';
    import close from '../svg/close.vue'
    // const axios = require('axios').default;
    export default defineComponent({
      name: 'goalshow',
      props: {
        msg: String,
        
      },
      components:{
        close,manualicon
      },
      data() {
          return {
           items:[],
        
          }
    
      },
      setup(props,context) {
      // const value1 = ref(500);
        const handleChange = value => {
          console.log(`selected ${value}`);
          context.emit("testchild")
        };
        var currentfactor=null;
    
        var  options1 =  [{
                            id:0,
                            value:1,  
                          },{
                            id:1,
                            value:2
                          },{
                            id:2,
                            value:3
                          },
                          {
                            id:3,
                            value:4
                          },
                          {
                            id:4,
                            value:5
                          },
                          
                          ]
    
    
    
          var  options2 =  [{
                            id:0,
                            value:"已经放弃",  
                          },{
                            id:1,
                            value:"已经完成"
                          },{
                            id:2,
                            value:"正在进行中"
                          },
                          
                          ]
    
    
            function createfactor(){
                  var env =  configure_options["env"]
                
                  console.log(configure_options["app_setting"][env])
                  var serverhost =configure_options["app_setting"][env]["serverhost"]
    
              // alert("穆阿标点击")
              // alert(this.selectedpriority)
            
                if(this.factorcontent==""){
                  alert("目标不能为空")
                  return
                }
    
                var tag  ={"factorname":this.factorcontent,"priority":this.selectedpriority}
                axios.post(serverhost + '/v1/createfactor',tag)
                 .then(response => {
                  console.log(response.data)
                  //可以将状态传入到父亲组件
                //    document.getElementById("addtaskinprojectzone").style.display="none"
                //  document.getElementById("goaltreedatapart").style.opacity=1
                //  document.getElementById("goaltreedatapart").style.pointerEvents="auto"
                //  document.getElementById("top").style.opacity=1
                //    emitter.emit("refresh_goaltree","createtaskinproject_component_ask")
                // 要让左侧进行对应的刷新
                this.showaddfactorzone = false
                 this.getfactors()
    
    
    
              })
              .catch(error=>{
                console.log(error)
            
              if(error.response.status==902){
                  alert("不能使用相同的目标名")
              }else{
                alert("服务器出错")
              }})
                return 
            }
    
    
            function modifyfactor(){
              // alert("检查海曙是否调用")
              if(this.currentfactor==null){
                alert("服务异常,请联系管理员")
                return
              }
              this.showeditfactorzone = false
              var env =  configure_options["env"]
              console.log("目前的目标是这样的")
              console.log(this.currentfactor)
                console.log(configure_options["app_setting"][env])
                var serverhost =configure_options["app_setting"][env]["serverhost"]
             
                var factor  ={"factorid":this.currentfactor.Factorid,factorname:this.factorcontent,priority:this.selectedpriority}
                axios.post(serverhost + '/v1/updatefactor',factor)
                 .then(response => {
                  // alert("检查为什么没有触发")
                  console.log(response.data)
                
                  this.getfactors()
    
                
                
                
                
                })



              
            }
    
          function showaddfactorzone_func(){
            this.factorcontent = ""
            this.selectedpriority = 0
            this.showaddfactorzone = true
            this.showeditfactorzone = false
          }
    
          function showeditfactorzone_func(factor){
            // alert(this.showeaddfactorzone)
            this.currentfactor = factor
            this.showaddfactorzone = false
            this.showeditfactorzone = true
           
            this.factorcontent = factor.Name
            this.selectedpriority = factor.Priority
            //  this.selectedstatus = goal.goalstatus
       
          //  alert(this.showeaddfactorzone )
            console.log(factor)
          }


          function closebox(){
// this.showboxcontainer = false
    this.showaddfactorzone = false;
    this.showeditfactorzone= false;
    // alert("1")
    // document.getElementById("goalshow").style.width="100vw";
    // document.getElementById("goalshow").style.maxWidth="100vw"
  }


  function getfactors(){

            var _this = this
               var env =  configure_options["env"]
           
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
    
        axios.get(serverhost+"/v1/getfactors").then(
         response=>{
           var data = response.data
               console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
          console.log(data);
          _this.items = data.factors
          _this.userlevel = data.userlevel
          if(_this.userlevel=="low"&& _this.items.length>10){
             _this.showaddbutton = false;
          }else{
            _this.showaddbutton = true;
          }
    
          // console.log(status);
          // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
         }
       ).catch(error=>{console.log(error)})

  }

    
    
        return {
          createfactor,
          modifyfactor,
          getfactors,
          closebox,
          symbol:"<",
          showaddbutton:ref(true),
          factorcontent:ref(""),
          userlevel:ref("normal"),
          showaddfactorzone:ref(false),
          showeditfactorzone:ref(false),
          showaddfactorzone_func,
          showeditfactorzone_func,
          currentfactor,
          handleChange,
          selectedpriority:ref(0),
          selectedstatus:ref("未完成"),
          options1,
          options2,
       
        };
      },
      mounted(){
     this.getfactors()
        
      }
    })
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="scss">
    
     h2{
      font-size: large;
      color: black;
      font-weight: 700;
      text-align: left;
     }


    #factormanagepage {
      display: flex;
      flex-direction:column;
      background: rgb(222, 235, 251);
      height: 100%;
    }
    
    #back{
      /* position: absolute;; */
      left: 10%;
      width: 200px;
    
      /* display:block; */
    }
    #goal{
        display:block;
    }
    
    #goalmanage{
      display: flex;
      /* justify-content: space-between; */
      margin-right: 100px;
    }
    
    #addgoalbutton{
      border: none;
      background: none;
    }
    
    
    .goalstatus_finished::after{
      color: red;
      content:" 已经完成了"
    }
    
    .goalstatus_giveup::after{
      color:rgb(129, 13, 238);
      content: " 放弃了x";
    }
    
    #factorshow{
      font-family: fangsong;
      text-align: left;
      margin-left: 50px;
      margin-right: 200px;
      /* position:absolute; */
      /* left: 40%; */
      .factorzone{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr ; 
   
      .singlefactor{
        border: 1px solid blue;
      // background: rgb(206, 9, 9);
      background: blue;
      text-align: center;
      color: white;
      margin-left:1px;
      padding:3px;
      border-radius: 10%;
      font-weight: 600;
    }
   
    }
    }
    #addgoal{
      display: flex;
      flex-direction: column;
      /* width: 70%; */
    
    }


    .closebutton{
  text-align: right;
}

    .factormanage{

min-width: 23vw;
display: flex;
// justify-content: space-between; 
margin-right: 100px;
flex-direction: column;
// border: #915353 dashed 1px;
background: rgb(238, 238, 245);

border: #915353 dashed 1px;
padding:3%;
border-radius: 5%;

button{
margin-top:14px ;
}
.tip{
padding-top: 14px;
text-align: left;
}
}
.backpage{
  // margin-left: auto;
  text-align: right;
  .backbutton{
  height: 45px;
    line-height: 45px;
    width: 150px;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    border: 1px solid #181828;
    // border-radius: 5%;
    color: #181828;
    text-decoration: none;
    background: snow;
    font-family: "handwriting1";
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: .1rem;
  
    border-radius:5px;
    overflow: hidden;
    text-align: center;

    &:hover{
      background: rgb(184, 115, 115);
      color:white;
    }
}
}

    </style>
    